<template>
  <div class="home">
    <el-card class="details-card">
      <div class="material-size">{{ goods }}</div>

      <el-skeleton v-if="loading" :rows="5" animated/>
      <table v-else style="width: 100%">
        <tr>
          <th>批次</th>
          <th>数量</th>
          <th>起订量</th>
          <th>价格</th>
          <th>周期</th>
        </tr>
<!--        {{list}}-->
        <tr v-for="(item,index) in stockList" :key="index">
          <td>{{ item.batch || '-' }}</td>
          <td>{{ item.stock }}</td>
          <td>{{ item.minOrderQuantity || 1 }}</td>
          <td>{{ item.price || '询价' }}</td>
          <td>{{ item.deliveryCycle || '询价' }}</td>
        </tr>
      </table>
      <el-card class="contactDetails">
        <div>邮箱 : info@ichelps.com</div>
        <div>联系方式 : 021-60907680</div>
      </el-card>
      <div class="btn">
        <el-button type="info" plain icon="el-icon-arrow-left" @click="onPage">上一页</el-button>
      </div>
    </el-card>
    <bottom-navigation></bottom-navigation>
  </div>
</template>

<script>
import BottomNavigation from "@/components/BottomNavigation";
import {listStock, detailGoods, spiderPrice} from '../../util/request'

export default {
  components: {BottomNavigation},
  data() {
    return {
      goodsId: '',
      stockList: [],
      goods: '',
      listLoading: true,
      spiderLoading: true,
    }
  },
  created() {
    this.goodsId = this.$route.params.id
    this.showDetails(this.goodsId)
    this.showStock(this.goodsId)
  },
  computed: {
    loading() {
      return (this.listLoading && this.spiderLoading) || !this.stockList.length
    }
  },
  methods: {
    // 获取 库存
    async showStock(goodsId) {
      const _this = this
      await listStock({goodsId}).then(res => {
        console.log("库存", res)
        const newList = res?.data?.data || []
        _this.stockList.push(...newList)
      }).finally(() => _this.listLoading = false)

      // 获取 爬虫爬取的库存
      await spiderPrice({goodsId}).then(res => {
        console.log("爬虫",res)
        const spiderList = res?.data?.data || []
        _this.stockList.push(...spiderList)
      }).finally(() => this.spiderLoading = false)

      if (_this.stockList.length <= 0) {
        _this.stockList.push({})
      }
    },
    // 获取 详情
    showDetails(goodsId) {
      detailGoods(goodsId).then(res => {
        this.goods = res?.data?.data?.goodsSn
      })
    },
    // 上一页
    onPage() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.details-card {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

.material-size {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
}

table {
  font-size: 16px;
  margin-bottom: 100px;
}

table, th {
  border: 1px solid black;
}

th {
  height: 50px;
}

td {
  height: 60px;
  line-height: 60px;
}

tr:hover {
  background-color: grey;
  color: #fff;
}

.contactDetails {
  position: absolute;
  bottom: 10px;
}

.contactDetails:hover {
  transform: scale(1.2);
  transition: all 1s;
  font-size: 25px;
}

.btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

</style>